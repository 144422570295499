@import "./scss/main.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Black";
  src: local("Inter-Black"),
    url(./assets/fonts/Inter-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold"),
    url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url(./assets/fonts/Inter-SemiBold.ttf) format("truetype");
}

.darkBtn {
  border-radius: 3rem;
  border: 1px solid var(--flowkit-charcoal, #222);
  background: var(--flowkit-charcoal, #222);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 1.8rem;
}
.darkBtnTwo {
  border-radius: 100px;
  border: 1px solid var(--flowkit-charcoal, #222);
  background: var(--flowkit-charcoal, #222);
  height: 4rem /* 64px */;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 1.8rem;
}

.darkOutlineBtn {
  border-radius: 3rem;
  border: 1px solid var(--flowkit-charcoal, #222);
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #222;
  font-family: "Inter-SemiBold";
  font-size: 1.8rem;
}

.navigationBar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.dropDownMenu {
  position: absolute;
  width: 238px;
  background-color: #fff;
  top: 110%;
  left: 0;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}
.multi-range-slider {
  display: flex;
  position: relative;
  border: none !important;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: none !important;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.multi-range-slider .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  text-align: center;
  line-height: 30px;
  background-color: #f3781f !important;
  border-radius: 15px;
  color: white;
  box-shadow: none !important;
  padding: 0px 5px;
  white-space: nowrap;
}

.multi-range-slider .thumb::before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 1.5px #f3781f !important;
  box-shadow: none !important;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
}
.multi-range-slider .bar-inner {
  background-color: #f3781f !important;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  border: none !important;
  justify-content: space-between;
  box-shadow: none !important;
}

.multi-range-slider .bar-left {
  width: 25%;
  background-color: #eaecf0;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 4px 0px;
}
.multi-range-slider .bar-right {
  width: 25%;
  background-color: #eaecf0;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 4px 0px;
}
