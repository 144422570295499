.GetStarted {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  margin-top: 10%;
  // background: red;
  height: 100%;
  @media (min-width: 1500px) {
    margin-top: 0%;
  }
  @media (min-width: 1200px) {
    margin-top: 10%;
  }
  @media (max-width: 1168px) {
    margin-top: 30%;
  }
  & > img {
    margin-bottom: 2rem;
  }

  &Heading {
    flex-direction: column;
    gap: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    color: var(--color-gray-800);
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
  &Text {
    color: var(--color-gray-500);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
  }

  &Text:last-of-type {
    margin-bottom: 2.4rem;
  }

  &Actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    width: 80%;
    position: relative;
    flex: 1;
    @media (max-width: 1230px) {
      width: 90%;
    }

    .AddBox > button,
    & > button {
      padding: 1rem 1.4rem;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2rem;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }
}
.SARtext {
  font-size: large;
}
.AddBox {
  position: relative;
  width: 100%;
}

.AddOptions {
  list-style: none;
  position: absolute;
  border-radius: 0.4rem;

  & > li {
    display: inline-block;
  }

  button {
    width: 16rem;
    padding: 1.1rem 0;
    background-color: #fff;
    border: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    white-space: nowrap;
    color: var(--color-gray-600);
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray-200);
    }
  }
}

.AddBtn,
.AddBtnTop {
  border: 1px solid #1f2937;
  background-color: #1f2937;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
  height: 80px;
}

.AddBtnTop {
  padding: 1.4rem 1.6rem;
  border-radius: 0.4rem;
  // margin-left: 1.6rem;

  font-size: 1.6rem;
}
.inputDateContainer {
  background: rgba(185, 28, 28, 0.1);
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.inputDate {
  width: 100%;
  height: 100%;
  padding: 0px 8px 0px 8px;
  background: rgba(185, 28, 28, 0.1);
  border-radius: 4px;
  font-size: 15px;
  color: #1f2937;
}
