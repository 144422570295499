@use "scss/abstract/mixins" as *;

.Header {
  z-index: 10;
  position: fixed !important;
  top: 0;
  // left: 0%;
  left: 15%;
  right: 0;
  min-height: 10.8rem;
  width: 100%;
  // padding: 2.4rem 3.2rem !important;
  padding: 2.4rem 3.2rem 2.4rem 2rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    padding: 3.7rem 3.5rem 3.7rem 2rem !important;
  }
  @media (max-width: 850px) {
    left: 0;
    padding: 3.7rem 3.2rem !important;
  }
}
.hideSideBar_Container {
  top: 0;
  left: 0;
  // left: 15%;
  right: 0;
  min-height: 10.8rem;
  width: 100%;
  // padding: 2.4rem 3.2rem !important;
  padding: 2.4rem 3.2rem 2.4rem 2rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    padding: 3.7rem 3.5rem 3.7rem 2rem !important;
  }
  @media (max-width: 850px) {
    left: 0;
    padding: 3.7rem 3.2rem !important;
  }
}
.Header_Collapsed {
  z-index: 10;
  position: relative;
  width: 100%;
  min-height: 10.8rem;
  // padding: 2.4rem 3.2rem !important;
  padding: 2.4rem 3.2rem 2.4rem 2.5rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  // @media (max-width: 850px) {
  //   padding: 3.7rem 3.2rem !important;
  // }
}
.hideSideBar_Collapsed {
  z-index: 10;
  position: relative;
  width: 100%;
  min-height: 10.8rem;
  // padding: 2.4rem 3.2rem !important;
  padding: 2.4rem 3.2rem 2.4rem 2.5rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.LogoutBtn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem 1.2rem;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 1px solid var(--color-gray-100);
  cursor: pointer;
  margin-left: 1.6rem;
  z-index: 1000;
  position: relative svg {
    color: var(--color-primary);
  }

  span {
    color: var(--color-gray-600);
    font-family: Inter;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;

    @include bp(smaller) {
      display: none;
    }
  }
}

.Notifications {
  display: flex;
  margin-right: 2.4rem;

  @include bp(large) {
    margin-right: 1.6rem;
  }

  @include bp(small-1) {
    margin-left: auto;
  }
}

.NotificationsToggler {
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  line-height: 0;
  cursor: pointer;

  span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--color-primary);
    font-size: 1.2rem;
    color: #fff;
    top: -0.6rem;
    right: -0.4rem;
  }
}

html[dir="rtl"] {
  .Notifications {
    margin-left: 2.4rem;

    @include bp(large) {
      margin-left: 1.6rem;
    }

    @include bp(small-1) {
      margin-right: auto;
    }
  }

  .LogoutBtn {
    margin-right: 1.6rem;
    margin-left: 0;
    display: block;
    @include bp(small-1) {
    }
  }
}
