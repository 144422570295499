:root {
  --color-primary: #f3781f;
  --color-primary-rgb: 243, 120, 31;
  --color-primary-dark: #7c3a09;
  --color-primary-light: #fff3eb;
  --color-primary-filter: #f3781f80;
  --color-secondary: #1f2937;

  --color-gray-light-1: #f3f4f6;
  --color-gray-light-2: #9ca3af;

  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;

  --color-red-600: #b91c1c;
  --color-red-700: #b91c1c;

  --color-green-50: #ecfdf3;
  --color-green-500: #10b981;
  --color-green-600: #059669;
  --color-green-700: #027a48;

  --shadow: 0 0 2rem rgba($color: #000000, $alpha: 0.2);
  --radius-medium: 0.8rem;
  --line: 1px solid var(--color-gray-light-1);
  --sidebar-max-width: 27.7rem;
  --sidebar-collapsed-max-width: 8.4rem;
  --color-auth-screens: #111827;
}

*:focus-visible {
  outline: 1px solid var(--color-primary);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-size: 1.4rem;
  font-family: "Inter";
  line-height: 1.5;
  overflow-x: hidden;
  // overflow-y: hidden;
}
