.DrawerCartPrimaryActionButtons {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & > button:nth-child(1),
  & > button:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 1;
    border-radius: 0.2rem;
    padding: 1.2rem;
    border: 1px solid var(--color-primary);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;

    cursor: pointer;

    svg {
      pointer-events: none;
    }
  }

  & > button:nth-child(1) {
    color: var(--color-primary);
    background-color: var(--color-primary-light);
  }
  & > button:nth-child(2) {
    color: #fff;
    background-color: var(--color-primary);
  }
}
