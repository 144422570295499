.PrimaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.1rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.Disabled {
  border: 1px solid gray;
  color: #fff;
  background-color: gray;
  transform: scale(0.92);
  cursor: not-allowed;
}

.Primary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.Secondary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary-light);
  color: var(--color-primary);
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
}
