.ReceiptBox {
  min-width: 30rem;
}

.ReceiptLanguageSwitcher {
  background-color: #fff;
  padding: 2rem 1.6rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  width: 100%;

  &Btn,
  &BtnSelected {
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-gray-600);
    cursor: pointer;

    &:last-of-type {
      margin-left: 1.6rem;
    }
    &:first-of-type {
      margin-left: auto;
    }
  }

  &BtnSelected {
    border-bottom: 1px solid var(--color-primary);
  }

  & > span {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.8rem;
    color: var(--color-gray-800);
  }
}

.Receipt {
  margin-top: 1.9rem;
  padding: 1.5rem 1.2rem 2.4rem 1.2rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  // max-height: 60vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  // font-family: "Inter";
  font-family: "Merchant";
}

.RestaurantNameLg1 {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--color-gray-600);
  margin-bottom: 1rem;
  font-family: sans-serif;
  margin-top: 5px;
  width: 100%;
  text-align: center;
}
.BranchNameLg1 {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--color-gray-600);
  margin-bottom: 1.2rem;
  font-family: sans-serif;
  margin-top: 5px;
  width: 100%;
  text-align: center;
}

.RestaurantNameLg2 {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--color-gray-600);
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-family: sans-serif;
  width: 100%;
  text-align: center;
}
.BranchPhone {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--color-gray-600);
  margin-bottom: 1rem;
  font-family: sans-serif;
}

.VAT {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: var(--color-gray-600);
  font-family: sans-serif;
}
.VAT_AR {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: var(--color-gray-600);
  margin-top: 0.5rem;
  font-family: sans-serif;
}

.Welcome {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--color-gray-600);
  margin-bottom: 0.8rem;
  margin-top: 1.5rem;
}
.mainFont {
  font-family: sans-serif;
}

.TaxText {
  display: flex;
  align-items: center;
  color: var(--color-gray-600);
  gap: 1.2rem;
  margin-bottom: 1.2rem;
  font-family: sans-serif;
  margin-top: 1.2rem;

  &Lg1 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.1rem;
  }

  &Lg2 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 0.6rem;
  }
}

.Id {
  margin-bottom: 1.6rem;
  border: 1px solid var(--color-gray-600);
  padding: 1.2rem 0.8rem;
  color: var(--color-gray-600);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.DateBox {
  color: var(--color-gray-600);
  margin-bottom: 0.2rem;
  font-family: sans-serif;
}

.DateBox,
.NameBox,
.FlexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.DateEng {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.1rem;
}
.DateAr {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.1rem;
}

.NameBox {
  color: var(--color-gray-600);
}
.NameEng {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.NameAr {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 0.6rem;
}

.LineBreaker {
  border: none;
  height: 1px;
  background-color: var(--color-gray-400);
  width: 100%;
  margin: 1rem 0;
}

.ReceiptTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.7rem;

  th {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1rem;
    color: var(--color-gray-600);
    padding-bottom: 1.2rem;
    font-family: sans-serif;
    padding-right: 0.5rem;
  }
  tr {
    padding-top: 15px !important;
  }

  td {
    color: var(--color-gray-600);
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1rem;
    padding-bottom: 0.4rem;
    vertical-align: top;
    // border-bottom: 1px dashed var(--color-gray-200);
    font-family: sans-serif;
    padding-right: 0.5rem;
    padding-top: 0.4rem;
  }

  th,
  td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
}

.ItemNameCell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 0.8rem;

  & > span:last-of-type {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.FlexBox {
  font-weight: 400;
  color: var(--color-gray-500);

  & > span:first-of-type {
    line-height: 1.1rem;
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
    // padding-bottom: 0.2rem;
  }

  & > span:last-of-type {
    line-height: 1.1rem;
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  & > :not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
}

.BarCodeImg {
  margin-top: 1.2rem;
}

.PrintBtn,
.ConfirmAndPrintBtn {
  margin-top: 1.2rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.ConfirmAndPrintBtn {
}

.PrintBtn {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid currentColor;
}
.CouponTab {
  display: flex;
  background-color: var(--color-primary-light);
  padding: 1.6rem;
  border-radius: 0.8rem;
  margin: 2.4rem 0;

  .CouponText {
    margin-right: auto;
    font-size: 1.6rem;
  }

  .CouponButton {
    background-color: transparent;
    color: var(--color-primary);
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
.CartTypeButtons {
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  flex-wrap: wrap;
  gap: 0.6rem;
}
.PriceList {
  margin-top: 2.6rem;
  border-top: 1px solid var(--color-gray-light-1);
  padding-top: 4rem;
  overflow-y: auto;
  flex: 1;
}

@media all {
  .pageBreak {
    display: none;
  }
  .pageBreakBefore {
    display: none;
  }
}

@media print {
  footer {
    page-break-after: always;
  }
  .pageBreak {
    page-break-after: always !important;
  }
  .pageBreakBefore {
    page-break-before: always !important;
  }
}
